.mentleg p{
  font-size: 12.6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.bg-gradient-slider {
  /* background: linear-gradient(100.99deg, #FFFFFF -8.36%, #81D1DE 33.67%, #39C3D1 63.53%); */
  mix-blend-mode: normal;
  opacity: 0.9;
  background: linear-gradient(100.99deg, #FFFFFF -8.36%, #81D1DE 33.67%, #39C3D1 63.53%);
}
.card-bg {
  background: linear-gradient(253.99deg, #03023C 8.38%, rgba(3, 2, 60, 0.604952) 33.67%, rgba(3, 2, 60, 0.94) 92.23%);

}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1000ms;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}


.bg_itw{
  background-position-y: 10%;
}



/* custom header */

.bg-hero {
  /* background: linear-gradient(0deg, rgba(3, 2, 60, 0.7), rgba(3, 2, 60, 0.7)), url(../public/images/bg.jpeg); */
}

